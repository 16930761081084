import React from "react";
import styles from "./RatingsCard.module.scss";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import emptyStar from "../../../Assets/results/empty-star.svg";
import filledStar from "../../../Assets/results/filled-star.svg";

export const RatingsCard = ({
  items,
  handleItemClick,
  ratings,
  handleRatingChange,
  handleR4Input,
  selectedItem,
  inputForR4,
  totalRatings,
  totalRatingsR5,
  r5Titles,
  ratingsR5,
  inputForR5,
  handleR5Input,
  handleRatingChange5,
  selectedItem5,
  handleItemClick5,
  MAX_RATING,
}) => {
  return (
    <div className={styles.itemList}>
      <div className={styles.round4}>Round 4</div>
      {items.map((item, index) => (
        <div
          key={index}
          className={styles.eachItem}
          style={{
            borderBottom: index === items.length - 1 ? "none" : "",
          }}
        >
          <div
            className={styles.itemTitle}
            onClick={() => handleItemClick(index)}
            style={{
              borderBottom: selectedItem !== index ? "none" : "",
              paddingBottom: selectedItem !== index ? "0px" : "",
            }}
          >
            <span
              className={`${styles.arrow} ${
                selectedItem === index ? styles.open : ""
              }`}
            >
              <KeyboardArrowRightIcon />
            </span>

            <span>{item.title}</span>
            <span className={styles.itemRating}>
              {Array(MAX_RATING)
                .fill()
                .map((_, i) => (
                  <span
                    key={i}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRatingChange(index, i + 1);
                    }}
                  >
                    {i < ratings[index] ? (
                      <img src={filledStar} alt="" />
                    ) : (
                      <img src={emptyStar} alt="" />
                    )}
                  </span>
                ))}
            </span>
          </div>
          {selectedItem === index && (
            <div className={styles.desc}>
              {item?.description &&
                item.description.map((ele, i) => (
                  <div key={i}>
                    <div>
                      {i + 1}. {ele.desc}
                    </div>
                    {ele?.subdesc?.length > 0 && (
                      <div>
                        {ele.subdesc.map((content, i) => (
                          <div key={i}>
                            {String.fromCharCode(97 + i)} {")"} {content}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              <div className={styles.eachItemInput}>
                <textarea
                  type="text"
                  placeholder="Add your comments"
                  value={inputForR4[index]}
                  onChange={(e) => handleR4Input(e, index)}
                />
              </div>
            </div>
          )}
        </div>
      ))}
      <div className={styles.totalScore}>
        <div className={styles.totalValue}>
          <div>Round 4 Total Score</div>
          <div>(average of all competencies score)</div>
        </div>
        <div>
          {totalRatings}

          <img src={filledStar} alt="" />
        </div>
      </div>
      <div className={`${styles.round4} ${styles.marginTop}`}>Round 5</div>
      {r5Titles.map((item, index) => (
        <div
          key={index}
          className={styles.eachItem}
          style={{
            borderBottom: index === r5Titles.length - 1 ? "none" : "",
          }}
        >
          <div
            className={styles.itemTitle}
            onClick={() => handleItemClick5(index)}
            style={{
              borderBottom: selectedItem !== index ? "none" : "",
              paddingBottom: selectedItem !== index ? "0px" : "",
            }}
          >
            <span
              className={`${styles.arrow} ${
                selectedItem5 === index ? styles.open : ""
              }`}
            >
              <KeyboardArrowRightIcon />
            </span>
            <span>{`${item}`}</span>
            <span className={styles.itemRating}>
              {Array(MAX_RATING)
                .fill()
                .map((_, i) => (
                  <span
                    key={i}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRatingChange5(index, i + 1);
                    }}
                  >
                    {i < ratingsR5[index] ? (
                      <img src={filledStar} alt="" />
                    ) : (
                      <img src={emptyStar} alt="" />
                    )}
                  </span>
                ))}
            </span>
          </div>
          {selectedItem5 === index && (
            <div className={styles.desc}>
              <div className={styles.eachItemInput}>
                <textarea
                  type="text"
                  placeholder="Add your comments"
                  value={inputForR5[index]}
                  onChange={(e) => handleR5Input(e, index)}
                />
              </div>
            </div>
          )}
        </div>
      ))}
      <div className={styles.totalScore}>
        <div className={styles.totalValue}>
          <div>Round 5 Total Score</div>
          <div>(average of all competencies score)</div>
        </div>
        <div>{totalRatingsR5} ★</div>
      </div>
    </div>
  );
};

export default RatingsCard;
