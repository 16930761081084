import React, { useState } from "react";
import styles from "./PreviousRoundPerformance.module.scss";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button } from "@mui/material";
import {
  downloadPDFQuestion,
  downloadZipProject,
} from "../../../Apis/downloadProject";
import LoadingOverlay from "../../Common/LoadingOverlay";

const ReportCard = ({ candidateProfile }) => {
  return (
    <div className={styles.reportCardContainer}>
      <div className={styles.title}>Previous Round Performance</div>
      <div className={styles.reportCard}>
        <EachRatings
          rating={candidateProfile?.candidateRoundStatus?.round1?.rating}
          title1={"Round 1: "}
          title2={"Technical Fundamentals Test"}
        />
        <EachRatings
          rating={candidateProfile?.candidateRoundStatus?.round2?.rating}
          title1={"Round 2: "}
          title2={"Coding Challenge"}
        />
        <EachRatings
          rating={candidateProfile?.candidateRoundStatus?.round3?.rating}
          title1={"Round 3: "}
          title2={"HR Assessment"}
        />
        <div className={styles.round}>
          <div className={styles.details}>
            <h3>
              <span>Round 4:</span> Practical Assignment
            </h3>
            <ProjectButtons
              candidateId={candidateProfile?.developerResponse?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const EachRatings = ({ rating, title1, title2 }) => {
  return (
    <div className={styles.round}>
      <div className={styles.details}>
        <h3>
          <span>{title1}</span>
          {title2}
        </h3>
        <p className={styles.score}>
          <strong>{rating?.toFixed(2)}</strong>
          /5
        </p>
      </div>
    </div>
  );
};

const ProjectButtons = ({ candidateId }) => {
  const [loading, setLoading] = useState(false);

  const handleProjectDetails = async (endpoint) => {
    setLoading(true);
    try {
      const data = {
        name: candidateId,
      };
      if (endpoint === "question") {
        await downloadPDFQuestion(data); // Ensure this is awaited
      } else if (endpoint === "submission") {
        await downloadZipProject(data); // Ensure this is awaited
      }
    } catch (err) {
      console.log("handleProjectDetails===>", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingOverlay loading={loading} zIndex={15000} />
      <div className={styles.assignmentsButton}>
        <Button onClick={() => handleProjectDetails("question")}>
          <FileDownloadOutlinedIcon />
          Download Assignment Details
        </Button>
        <Button onClick={() => handleProjectDetails("submission")}>
          <FileDownloadOutlinedIcon />
          Download Submitted Assignment
        </Button>
      </div>
    </>
  );
};

export default ReportCard;
