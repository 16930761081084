import { DOWNLOAD_RESUME, GET_CANDIDATE_ID } from "./URL";
import axiosInstance from "./axiosInstance";

export const getCandidateId = async (meet_id) => {

  const URL = GET_CANDIDATE_ID + `${meet_id}`;

  let Config = {};
  const data = {};
  return await axiosInstance.post(URL, data, Config).then((res) => res.data);
};

export const getCandidateResume = async (data) => {

  const URL = DOWNLOAD_RESUME;

  const config = {
    url: URL,
    method: "POST",
    responseType: "blob", // Specify the response type as 'blob'
    data: data, // Include the request body data
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  return await axiosInstance(config).then((response) => {
    // Create a URL for the file blob
    const url = window.URL.createObjectURL(new Blob([response.data]));
    return url;
  });
};
