import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Link,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { expertLogin } from "../../Apis/login";

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [cookies, setCookie] = useCookies(["user"]);
  const [email, setEmail] = useState(
    cookies.username ? cookies.username : undefined
  );
  const [pass, setPass] = useState(
    cookies.Password ? cookies.Password : undefined
  );
  const [remember, setRemember] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPass(event.target.value);
  const handleRememberChange = (event) => setRemember(event.target.checked);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      officialMail: email,
      password: pass,
    };

    const loginSuccessful = await expertLogin(data);

    if (loginSuccessful) {
      if (remember) rememberCookie();
      navigate(`/dashboard`);
    }
  };

  const rememberCookie = () => {
    setCookie("username", email, { path: "/" });
    setCookie("Password", pass, { path: "/" });
  };

  const boxShadowStyle = {
    boxShadow: 2,
    borderRadius: 2,
    padding: 3,
    backgroundColor: "#ffffff",
    // border: "1px solid red",
    minWidth: "450px",
    paddingTop: "30px",
    paddingBottom: "30px",
    marginTop: "0px",
  };

  return (
    <Container component="main" maxWidth="xs" className="login-container">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "#ffffff",
          ...boxShadowStyle,
        }}
      >
        <Typography component="h1" variant="h5" className="login-title">
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Official Email ID"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
            type="email"
            className="user-email"
          />
          <FormControl fullWidth variant="outlined" margin="normal" required>
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? "text" : "password"}
              value={pass}
              onChange={handlePasswordChange}
              className="user-pass"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mt: 2,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={remember}
                  onChange={handleRememberChange}
                />
              }
              label="Remember me?"
            />
            <Link href="/forgot-password" variant="body2">
              Forgot Password?
            </Link>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            className="button-end"
          >
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
