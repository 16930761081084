import { SUBMIT_ROUND_PASSED } from "./URL";
import axiosInstance from "./axiosInstance";

export const submitRound = async (candidateId) => {
  let Config = {
    // headers: {
    //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // },
    header: {},
  };

  const URL = SUBMIT_ROUND_PASSED;

  const data = {
    id: candidateId,
    status: true,
  };
  return await axiosInstance.post(URL, data, Config).then((res) => res.data);
};
