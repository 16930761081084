import { GET_R5_COMPETENCIES } from "./URL";
import axiosInstance from "./axiosInstance";

export const candidateSkillsForExpertRound = async (data
) => {

  const URL = GET_R5_COMPETENCIES;

  let Config = {
    // headers: {
    //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // },
  };
  return await axiosInstance.post(URL, data, Config).then((res) => res.data);
};
