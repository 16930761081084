export const allEvents = (data) => {
  return data.map((meeting, index) => {
    // Calculate the end time by adding the duration to the start time
    const startTime = new Date(meeting.startTime);
    const endTime = new Date(meeting.endTime);
    // const endTime = new Date(startTime.getTime() + meeting.duration * 60000);

    return {
      id: index + 1,
      title: meeting?.name, //left
      start: startTime,
      end: endTime,
      // uuid: meeting?.uuid,
      // hostId: meeting?.hostId,
      // duration: meeting?.duration,
      timezone: meeting?.timeZone,
      // created_at: new Date(meeting.created_at),
      join_url: meeting?.join_url,
      name: meeting?.name,
      // meetingStatus: meeting?.meetingStatus,
      meet_id: meeting?.meetingId,
      hrMeetingStatus: meeting?.expertMeetingStatus,
    };
  });
};