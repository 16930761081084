import React, { useState, useEffect } from "react";
import "./CustomAvailability.scss";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Select,
  MenuItem,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  getCustomAvailability,
  saveAllCustomAvailability,
} from "../../../Apis/Availability";
import LoadingOverlay from "../../Common/LoadingOverlay";

const generateTimeOptions = () => {
  const options = [];
  const start = new Date();
  start.setHours(0, 0, 0, 0);
  for (let i = 0; i < 48; i++) {
    const timeString = start.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    options.push(timeString);
    start.setMinutes(start.getMinutes() + 30);
  }
  return options;
};

const timeOptions = generateTimeOptions();

const TimeSlotSelector = ({
  slot,
  index,
  uniqueId,
  date,
  getAvailableTimes,
  handleTimeChange,
  addTimeSlot,
  removeTimeSlot,
  removeDate,
}) => {
  return (
    <Box className="each-slot">
      <div className="date-input">
        <TextField
          type="date"
          value={date || ""}
          onChange={(event) => handleTimeChange(uniqueId, index, "date", event)}
          disabled={index !== 0}
        />
      </div>
      <FormControl className="start-time">
        <Select
          value={slot?.startTime ?? ""}
          onChange={(event) =>
            handleTimeChange?.(uniqueId, index, "startTime", event)
          }
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>Start Time</em>;
            }
            return selected;
          }}
        >
          <MenuItem disabled value="">
            <em>Start Time</em>
          </MenuItem>
          {getAvailableTimes?.(uniqueId, index, "startTime")?.map((time) => (
            <MenuItem key={time} value={time}>
              {time}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="to">to</div>
      <FormControl className="end-time">
        <Select
          value={slot?.endTime ?? ""}
          onChange={(event) =>
            handleTimeChange?.(uniqueId, index, "endTime", event)
          }
          disabled={!slot?.startTime}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>End Time</em>;
            }
            return selected;
          }}
        >
          <MenuItem disabled value="">
            <em>End Time</em>
          </MenuItem>
          {getAvailableTimes?.(uniqueId, index, "endTime")?.map((time) => (
            <MenuItem key={time} value={time}>
              {time}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className="time-zone">
        <Select
          value={slot?.timeZone ?? "Asia/Kolkata"}
          onChange={(event) =>
            handleTimeChange?.(uniqueId, index, "timeZone", event)
          }
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>Time Zone</em>;
            }
            return selected;
          }}
        >
          <MenuItem disabled value="">
            <em>Time Zone</em>
          </MenuItem>
          <MenuItem value="Asia/Kolkata">Asia/Kolkata</MenuItem>
          <MenuItem value="America/New_York">America/New_York</MenuItem>
          <MenuItem value="Europe/London">Europe/London</MenuItem>
          {/* Add more time zones as needed */}
        </Select>
      </FormControl>
      <IconButton
        onClick={() => addTimeSlot?.(uniqueId)}
        sx={{ marginRight: 1, marginLeft: 1 }}
      >
        <AddIcon className="icons" />
      </IconButton>
      <IconButton onClick={() => removeTimeSlot?.(uniqueId, index)}>
        <DeleteOutlinedIcon className="icons" />
      </IconButton>
      {index === 0 && (
        <Button className="delete-all" onClick={() => removeDate?.(uniqueId)}>
          Delete All
        </Button>
      )}
    </Box>
  );
};

const DateSelector = ({
  uniqueId,
  date,
  availability,
  handleTimeChange,
  addTimeSlot,
  removeTimeSlot,
  getAvailableTimes,
  removeDate,
}) => {
  return (
    <Grid item>
      <Box>
        {availability?.slots?.map((slot, index) => (
          <TimeSlotSelector
            key={index}
            slot={slot}
            index={index}
            uniqueId={uniqueId}
            date={date}
            getAvailableTimes={getAvailableTimes}
            handleTimeChange={handleTimeChange}
            addTimeSlot={addTimeSlot}
            removeTimeSlot={removeTimeSlot}
            removeDate={removeDate}
          />
        ))}
      </Box>
    </Grid>
  );
};

export const CustomAvailability = () => {
  const [availability, setAvailability] = useState({});
  const [loading, setLoading] = useState(false);

  const hasAvailabilityItems = Object.keys(availability).length > 0;

  const handleTimeChange = (uniqueId, index, type, event) => {
    const newTime = event?.target?.value;
    const slots = [...(availability[uniqueId]?.slots ?? [])];

    if (type === "date") {
      const newDate = event.target.value;
      setAvailability((prevAvailability) => {
        const newAvailability = { ...prevAvailability };
        newAvailability[uniqueId].date = newDate;
        return newAvailability;
      });
      return;
    }

    slots[index][type] = newTime;

    if (type === "endTime") {
      const startTime = slots[index]?.startTime;
      if (isOverlapping(uniqueId, startTime, newTime, index)) {
        toast.error("Time range overlaps with existing slot!");
        slots[index][type] = ""; // Reset the time
        setAvailability({
          ...availability,
          [uniqueId]: {
            ...availability[uniqueId],
            slots,
          },
        });
        return;
      }
    } else if (type === "startTime") {
      const endTime = slots[index]?.endTime;
      if (isOverlapping(uniqueId, newTime, endTime, index)) {
        toast.error("Time range overlaps with existing slot!");
        slots[index][type] = ""; // Reset the time
        setAvailability({
          ...availability,
          [uniqueId]: {
            ...availability[uniqueId],
            slots,
          },
        });
        return;
      }
    }

    setAvailability({
      ...availability,
      [uniqueId]: {
        ...availability[uniqueId],
        slots,
      },
    });
  };

  const addTimeSlot = (uniqueId) => {
    setAvailability((prevAvailability) => ({
      ...prevAvailability,
      [uniqueId]: {
        ...prevAvailability[uniqueId],
        slots: [
          ...(prevAvailability[uniqueId]?.slots ?? []),
          { startTime: "", endTime: "", timeZone: "Asia/Kolkata" },
        ],
      },
    }));
  };

  const removeTimeSlot = (uniqueId, index) => {
    const slots = [...(availability[uniqueId]?.slots ?? [])];
    slots.splice(index, 1);
    setAvailability((prevAvailability) => ({
      ...prevAvailability,
      [uniqueId]: {
        ...prevAvailability[uniqueId],
        slots,
      },
    }));
  };

  const removeDate = (uniqueId) => {
    setAvailability((prevAvailability) => {
      const newAvailability = { ...prevAvailability };
      delete newAvailability[uniqueId];
      return newAvailability;
    });
  };

  const isTimeInRange = (time, start, end) => {
    const timeIndex = timeOptions.indexOf(time);
    const startIndex = timeOptions.indexOf(start);
    const endIndex = timeOptions.indexOf(end);
    return timeIndex > startIndex && timeIndex < endIndex;
  };

  const isOverlapping = (
    uniqueId,
    newStartTime,
    newEndTime,
    currentSlotIndex
  ) => {
    if (!newStartTime || !newEndTime) return false;
    const newStartIndex = timeOptions.indexOf(newStartTime);
    const newEndIndex = timeOptions.indexOf(newEndTime);
    return availability?.[uniqueId]?.slots?.some((slot, index) => {
      if (index === currentSlotIndex || !slot?.startTime || !slot?.endTime) {
        return false;
      }
      const startIndex = timeOptions.indexOf(slot?.startTime);
      const endIndex = timeOptions.indexOf(slot?.endTime);
      return (
        (newStartIndex >= startIndex && newStartIndex < endIndex) ||
        (newEndIndex > startIndex && newEndIndex <= endIndex) ||
        (startIndex >= newStartIndex && startIndex < newEndIndex) ||
        (endIndex > newStartIndex && endIndex <= newEndIndex)
      );
    });
  };

  const getAvailableTimes = (uniqueId, currentSlotIndex, type) => {
    const selectedTimes =
      availability?.[uniqueId]?.slots
        ?.flatMap((slot, index) =>
          index === currentSlotIndex ? [] : [[slot?.startTime, slot?.endTime]]
        )
        ?.filter(([startTime, endTime]) => startTime && endTime) ?? [];

    const availableTimes = timeOptions.filter((time) => {
      return !selectedTimes.some(([startTime, endTime]) => {
        const timeIndex = timeOptions.indexOf(time);
        const startIndex = timeOptions.indexOf(startTime);
        const endIndex = timeOptions.indexOf(endTime);
        if (type === "startTime") {
          return timeIndex >= startIndex && timeIndex < endIndex;
        } else if (type === "endTime") {
          return (
            (timeIndex > startIndex ||
              (startIndex === timeOptions.length - 1 && timeIndex === 0)) &&
            timeIndex <= endIndex
          );
        }
        return false;
      });
    });

    if (type === "endTime") {
      const startTime =
        availability?.[uniqueId]?.slots?.[currentSlotIndex]?.startTime;
      if (startTime) {
        const startIndex = timeOptions.indexOf(startTime);
        return availableTimes.filter(
          (time) =>
            timeOptions.indexOf(time) > startIndex ||
            (startIndex === timeOptions.length - 1 &&
              timeOptions.indexOf(time) === 0) ||
            time === "12:00 AM"
        );
      }
    }

    return availableTimes;
  };

  const performChecks = (availability) => {
    // Check for incomplete slots
    const hasIncompleteSlots = Object.values(availability ?? {}).some((day) =>
      day.slots.some((slot) => slot.startTime && !slot.endTime)
    );

    if (hasIncompleteSlots) {
      toast.error("All slots must have an end time.");
      return false;
    }

    // Check for invalid time ranges
    const hasInvalidTimeRanges = Object.values(availability ?? {}).some((day) =>
      day.slots.some((slot) => {
        const fromTimeIndex = timeOptions.indexOf(slot.startTime);
        const toTimeIndex = timeOptions.indexOf(slot.endTime);
        return (
          fromTimeIndex !== -1 &&
          toTimeIndex !== -1 &&
          toTimeIndex <= fromTimeIndex
        );
      })
    );

    if (hasInvalidTimeRanges) {
      toast.error("One or more slots have invalid time ranges.");
      return false;
    }

    return true;
  };

  const createPayload = (availability) => {
    const expertId = sessionStorage?.getItem("mail");

    const customAvailability = Object.values(availability)
      .filter((data) => data.slots.length > 0)
      .flatMap((data) =>
        data.slots.map((slot) => ({
          date: data.date,
          fromTime: slot.startTime,
          toTime: slot.endTime,
          timeZone: slot.timeZone,
        }))
      )
      .filter((slot) => slot.date && slot.fromTime && slot.toTime);

    return {
      expertId,
      customAvailability,
    };
  };

  const handleSave = async () => {
    if (!performChecks(availability)) {
      return;
    }

    const payload = createPayload(availability);

    try {
      setLoading(true);
      await saveAllCustomAvailability?.(payload); // replace with your actual API call
      toast.success("Availability saved successfully.");
    } catch (error) {
      toast.error("Failed to save availability.");
    } finally {
      setLoading(false);
    }

    console.log("Payload to send:", payload);
  };

  useEffect(() => {
    // Fetch and prefill availability if needed
    fetReurringAvailability();
  }, []);

  const fetReurringAvailability = async () => {
    try {
      setLoading(true);
      const email = sessionStorage?.getItem("mail");
      const data = await getCustomAvailability?.(email);
      if (data) prefillAvailability(data);
    } catch (err) {
      let msg;
      if (err?.response?.data?.exception) {
        msg = err?.response?.data?.exception;
      } else {
        msg = "Something Went Wrong!";
      }
      toast.error(msg);
    } finally {
      setLoading(false);
    }
  };

  const prefillAvailability = (data) => {
    const updatedAvailability = {};

    data?.forEach((slot) => {
      const date = slot?.date;
      const uniqueId = date + "-" + new Date().getTime().toString(); // Generate a unique key for the new date
      if (!updatedAvailability[uniqueId]) {
        updatedAvailability[uniqueId] = {
          date,
          slots: [],
        };
      }
      updatedAvailability[uniqueId]?.slots?.push({
        startTime: slot?.fromTime,
        endTime: slot?.toTime,
        timeZone: slot?.timeZone,
      });
    });

    setAvailability(updatedAvailability);
  };

  const addNewDateRow = () => {
    const uniqueKey = new Date().getTime().toString(); // Generate a unique key for the new date
    setAvailability((prevAvailability) => ({
      ...prevAvailability,
      [uniqueKey]: {
        date: "",
        slots: [{ startTime: "", endTime: "", timeZone: "Asia/Kolkata" }],
      },
    }));
  };

  return (
    <>
      <LoadingOverlay loading={loading} />
      <Box className="custom-availability-container">
        <ToastContainer />
        <Grid container alignItems="center">
          <Grid
            item
            style={{
              position: hasAvailabilityItems ? "absolute" : "",
              right: hasAvailabilityItems ? "16px" : "",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={addNewDateRow}
              className="custom-button-date"
            >
              Add Date
            </Button>
          </Grid>
        </Grid>
        <Box mt={3}>
          {Object.keys(availability ?? {}).map((uniqueId) => (
            <div className="each-date">
              <Grid container className="headers-containers">
                <Grid item>
                  <Typography className={["table-headers", "first-item"]}>
                    {/* Day */}
                    Select Date
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="table-headers">
                    {/* Start Time */}
                    Select Time
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="table-headers">
                    {/* End Time */}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="table-headers">Time Zone</Typography>
                </Grid>
              </Grid>
              <DateSelector
                key={uniqueId}
                uniqueId={uniqueId}
                date={availability[uniqueId].date}
                availability={availability[uniqueId]}
                handleTimeChange={handleTimeChange}
                addTimeSlot={addTimeSlot}
                removeTimeSlot={removeTimeSlot}
                getAvailableTimes={getAvailableTimes}
                removeDate={removeDate}
              />
            </div>
          ))}
        </Box>
        {hasAvailabilityItems && (
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                className="custom-button"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default CustomAvailability;
