import { useEffect, useState } from "react";
import "./DetailsCard.scss";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { getCandidateResume } from "../../../Apis/candidateDetails";
import LoadingOverlay from "../../Common/LoadingOverlay";
export const DetailsCard = ({
  meetingDetails,
  candidateProfile,
  disableJoin,
}) => {
  return (
    <div className="details-container">
      <JoinMeetingCard
        meetingDetails={meetingDetails}
        disableJoin={disableJoin}
      />
      <CandidateInfoCard
        meetingDetails={meetingDetails}
        candidateProfile={candidateProfile}
      />
    </div>
  );
};

const JoinMeetingCard = ({ meetingDetails, disableJoin }) => {
  const handleJoinMeeting = () => {
    window.open(meetingDetails?.joinUrl, "_blank");
  };
  return (
    <div className="card1">
      <div className="title">
        If you are unable to connect, please click on the button below:
      </div>
      <div className="join-button">
        <button
          disabled={disableJoin ? true : false}
          onClick={() => handleJoinMeeting()}
          style={{
            opacity: disableJoin ? ".7" : "1",
            cursor: disableJoin ? "not-allowed" : "",
          }}
        >
          Join the Meeting
        </button>
      </div>
    </div>
  );
};

const CandidateInfoCard = ({ meetingDetails, candidateProfile }) => {
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedTime, setFormattedTime] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDownLoadResume = async () => {
    setLoading(true);
    try {
      const data = {
        id: candidateProfile?.developerResponse?.id,
      };
      const res = await getCandidateResume(data);
      const downloadLink = document.createElement("a");
      downloadLink.href = res;
      // Set the file name
      downloadLink.setAttribute("download", "resume.pdf");
      // Trigger the download by programmatically clicking the link
      downloadLink.click();
      console.log("handleDownload==>", res);
    } catch (err) {
      console.log("handleDownload error ==>", err);
    } finally {
      setLoading(false);
    }
  };

  const handleFormattedDateAndTime = () => {
    const meetingDate = new Date(meetingDetails.start);
    const newDate = meetingDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const newTime = meetingDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    setFormattedDate(newDate);
    setFormattedTime(newTime);
  };

  useEffect(() => {
    handleFormattedDateAndTime();
  }, [meetingDetails]);

  return (
    <>
      {loading && <LoadingOverlay loading={loading} />}
      <div className="card">
        <div className="card-section">
          <div className="card-item">
            <div className="label">Candidate Name</div>
            <div className="value">
              {candidateProfile?.developerResponse?.fullName}
            </div>
          </div>
          <div className="card-item">
            <div className="label">Date of Interview</div>
            <div className="value">{formattedDate}</div>
          </div>
          <div className="card-item">
            <div className="label">Time of Interview</div>
            <div className="value">{formattedTime}</div>
          </div>
        </div>
        <div className="card-section section2">
          <div className="card-item">
            <div className="label">Round 1</div>
            <div className="value">
              {candidateProfile?.candidateRoundStatus?.round1?.rating.toFixed(
                2
              )}
            </div>
          </div>
          <div className="card-item">
            <div className="label">Round 2</div>
            <div className="value">
              {" "}
              {candidateProfile?.candidateRoundStatus?.round2?.rating.toFixed(
                2
              )}
            </div>
          </div>
          <div className="card-item">
            <button
              className="download-button"
              onClick={() => handleDownLoadResume()}
            >
              <FileDownloadOutlinedIcon />
              Download Resume
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
