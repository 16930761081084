import { useEffect, useState } from "react";
import { DashboardStats } from "../../Component/Dashboard/Stats/Stats";
import { Header } from "../../Component/Header/Header";
import { currentMonthEvents } from "../../Apis/monthEventDetails";
import MyCalendar from "../../Component/Dashboard/CalendarUI/MyCalendar";

export const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [currentMonthDetails, setCurrentMonthDetails] = useState({});

  const monthEvents = async () => {
    try {
      setLoading(true);
      const res = await currentMonthEvents();
      setCurrentMonthDetails({ ...res });
    } catch (err) {
      console.log("monthEvents ===>", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    monthEvents();
  }, []);

  return (
    <div>
      <Header />
      {loading ? (
        <div>Loading...</div>
      ) : (
        <DashboardStats currentMonthDetails={currentMonthDetails} />
      )}
      <MyCalendar />
    </div>
  );
};
