import { useState } from "react";
import "./ComentsAndDescription.scss";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import filledStar from "../../../Assets/results/filled-star.svg";

export const ComentsAndDescription = ({
  handleResultSubmitClick,
  comments,
  setComments,
  disableJoin,
}) => {
  return (
    <div className="comment-description-wrapper">
      <Comments comments={comments} setComments={setComments} />
      <Description />
      <SubmitButtons
        handleResultSubmitClick={handleResultSubmitClick}
        disableJoin={disableJoin}
      />
    </div>
  );
};

export const Comments = ({ comments, setComments }) => {
  return (
    <div>
      <textarea
        value={comments}
        className="comment"
        name="description"
        id="description"
        placeholder="Write overall comment..."
        onChange={(e) => setComments(e.target.value)}
      ></textarea>
    </div>
  );
};

export const Description = () => {
  const [viewDetails, setViewDetails] = useState(true);
  return (
    <div className="description-container">
      <div className="know-more">
        <div
          onClick={() => setViewDetails(!viewDetails)}
          className="know-more-header"
        >
          <div className="know-more-text">
            Know more about ratings
            {/* <span className="star-icon">
              <img src={filledStar} alt="star" />
            </span> */}
          </div>
          <div className={`arrow-icon ${viewDetails && "open"}`}>
            <KeyboardArrowDownIcon />
          </div>
        </div>
        <div className="start-details">
          {viewDetails &&
            starDesc.map((item, index) => (
              <div key={index} className="each-star">
                <span className="star-number">{index < 5 && index + 1}</span>
                <span className="star-image">
                  {index < 5 && <img src={filledStar} alt="star" />}
                </span>
                <span className="star-description">
                  {index < 5 && ":"} {item}
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export const SubmitButtons = ({ handleResultSubmitClick, disableJoin }) => {
  const navigate = useNavigate();

  const goToPreviousPage = () => {
    navigate(-1);
  };

  return (
    <div className="buttons-wrapper">
      <button className="button go-back" onClick={goToPreviousPage}>
        Go Back
      </button>
      <button
        className="button submit-result"
        onClick={() => handleResultSubmitClick()}
        disabled={disableJoin ? true : false}
        style={{
          opacity: disableJoin ? ".7" : "1",
          cursor: disableJoin ? "not-allowed" : "",
        }}
      >
        Submit Result
      </button>
    </div>
  );
};

const starDesc = [
  "Does not exhibit",
  "Does bare minimum",
  "Consistency in Performance",
  "Overcome Obstacles & Stretches",
  "Shows exceptional knowledge and attitute",
  "** If score is below 2 in any of these competencies, the candidature will be rejected  ",
  "** If total score is below 2.5 in any of these competencies, the candidature will be rejected",
];
