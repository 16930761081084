import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { LoginMain } from "../Pages/Login/LoginMain";
import { NotFoundPage } from "../Pages/NotFound/NotFound";
import { Dashboard } from "../Pages/Dashboard/Dashboard";
import { MeetingDetails } from "../Pages/MeetingDetails/MeetingDetails";
import { AvailabilitySetter } from "../Pages/MyAvailabillity/MyAvailabillity";
import { AssessmentResult } from "../Pages/AssessmentResult/AssessmentResult";

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginMain />} />
        <Route path="/login" element={<LoginMain />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/meetingDetails" element={<MeetingDetails />} />
        <Route path="/my-availability/*" element={<AvailabilitySetter />} />
        <Route path="/assessment-result" element={<AssessmentResult />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
};
