export const EXPERT_LOGIN = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_AUTHENTICATION + "/api/v1/user/hr/expert/login";

export const MONTH_EVENT = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_EXPERT + "/meetings/completed/pending?page_size=200&page_number=1&type=scheduled";

export const GET_CALENDAR_EVENTS = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_EXPERT + "/HR/meetings?page_size=200&page_number=1";

export const DECLINE_METTING = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_EXPERT + `/update/expert/meeting/status`

export const GET_CANDIDATE_ID = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_EXPERT + "/candidate/details/"

export const DOWNLOAD_RESUME = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_AZURE_STORAGE + `/downloadblob`

export const AVAILABILITY_RECURRING_GET = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_EXPERT + `/api/v1/round5/expert/availability/get/recurring/availability`

export const AVAILABILITY_RECURRING_SAVE = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_EXPERT + `/api/v1/round5/expert/availability/recurring`

export const AVAILABILITY_CUSTOM_GET = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_EXPERT + `/api/v1/round5/expert/availability/get/custom/availability`

export const AVAILABILITY_CUSTOM_SAVE = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_EXPERT + `/api/v1/round5/expert/availability/custom`

export const AVAILABILITY_ALL_GET = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_EXPERT + `/api/v1/round5/expert/availability/get`

export const AVAILABILITY_OVERRIDE_SAVE = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_EXPERT + `/api/v1/round5/expert/availability/override/recurring`

export const DOWNLOAD_QUESTIONS_PDF = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ROUND5 + `/api/v1/round5/get/project/question`;

export const DOWNLOAD_SUBMITTED_ASSIGNMENT = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ROUND5 + `/api/v1/round5/get/project/submission`;

export const GET_R5_COMPETENCIES = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ROUND5 + `/api/v1/round5/get/languages`;

export const ROUND5_RESULT_SUBMIT = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ROUND5 + `/api/v1/round5/save`;

export const SUBMIT_ROUND_PASSED = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_DEVELOPER + `/api/v1/assessment/round5/status`;