import { AVAILABILITY_ALL_GET, AVAILABILITY_CUSTOM_GET, AVAILABILITY_CUSTOM_SAVE, AVAILABILITY_OVERRIDE_SAVE, AVAILABILITY_RECURRING_GET, AVAILABILITY_RECURRING_SAVE } from "./URL"

import axiosInstance from "./axiosInstance";

export const getRecurringAvailability = async (email) => {
    let endpoint = AVAILABILITY_RECURRING_GET;
    const URL = endpoint + `?expertId=${email}`

    let Config = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    };

    return await axiosInstance.get(URL, Config).then(res => res.data)
}

export const saveAllRecurringAvailability = async (data) => {
    let Config = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    };
    const URL = AVAILABILITY_RECURRING_SAVE;

    return await axiosInstance.post(URL, data, Config).then((res) => res.data);
}


export const getCustomAvailability = async (email) => {
    let endpoint = AVAILABILITY_CUSTOM_GET;
    const URL = endpoint + `?expertId=${email}`

    let Config = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    };

    return await axiosInstance.get(URL, Config).then(res => res.data)
}

export const saveAllCustomAvailability = async (data) => {
    let Config = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    };
    const URL = AVAILABILITY_CUSTOM_SAVE;

    return await axiosInstance.post(URL, data, Config).then((res) => res.data);
}


export const getAllAvailability = async (email) => {
    let endpoint = AVAILABILITY_ALL_GET;
    const URL = endpoint + `?expertId=${email}`

    let Config = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    };

    return await axiosInstance.get(URL, Config).then(res => res.data)
}


export const saveOverrideAvailability = async (data) => {
    let Config = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    };
    const URL = AVAILABILITY_OVERRIDE_SAVE;

    return await axiosInstance.post(URL, data, Config).then((res) => res.data);
}