import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "./OverWrite.scss";
import {
  getAllAvailability,
  saveOverrideAvailability,
} from "../../../Apis/Availability";
import LoadingOverlay from "../../Common/LoadingOverlay";

const localizer = momentLocalizer(moment);

const generateTimeOptions = () => {
  const options = [];
  const start = new Date();
  start.setHours(0, 0, 0, 0);
  for (let i = 0; i < 48; i++) {
    const timeString = start.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    options.push(timeString);
    start.setMinutes(start.getMinutes() + 30);
  }
  return options;
};

const timeOptions = generateTimeOptions();

const TimeSlotSelector = ({
  slot,
  index,
  date,
  getAvailableTimes,
  handleTimeChange,
  addTimeSlot,
  removeTimeSlot,
  day,
  onlyOneSlot,
}) => {
  return (
    <Box className="each-slot">
      <Typography className="day-name">{index === 0 ? day : ""}</Typography>
      <FormControl className="start-time">
        <Select
          value={slot.startTime}
          onChange={(event) =>
            handleTimeChange(date, index, "startTime", event)
          }
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>Start Time</em>;
            }
            return selected;
          }}
        >
          <MenuItem disabled value="">
            <em>Start Time</em>
          </MenuItem>
          {onlyOneSlot && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {getAvailableTimes(date, index, "startTime").map((time) => (
            <MenuItem key={time} value={time}>
              {time}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="to">to</div>
      <FormControl className="end-time">
        <Select
          value={slot.endTime}
          onChange={(event) => handleTimeChange(date, index, "endTime", event)}
          disabled={!slot.startTime}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>End Time</em>;
            }
            return selected;
          }}
        >
          <MenuItem disabled value="">
            <em>End Time</em>
          </MenuItem>
          {onlyOneSlot && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {getAvailableTimes(date, index, "endTime").map((time) => (
            <MenuItem key={time} value={time}>
              {time}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className="time-zone">
        <Select
          value={slot.timeZone || "Asia/Kolkata"}
          onChange={(event) => handleTimeChange(date, index, "timeZone", event)}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>Time Zone</em>;
            }
            return selected;
          }}
        >
          <MenuItem disabled value="">
            <em>Time Zone</em>
          </MenuItem>
          <MenuItem value="Asia/Kolkata">Asia/Kolkata</MenuItem>
          <MenuItem value="America/New_York">America/New_York</MenuItem>
          <MenuItem value="Europe/London">Europe/London</MenuItem>
          {/* Add more time zones as needed */}
        </Select>
      </FormControl>
      <IconButton
        onClick={() => addTimeSlot(date)}
        sx={{ marginRight: 1, marginLeft: 1 }}
      >
        <AddIcon className="icons" />
      </IconButton>
      {/* {index === 0 && (
        <IconButton onClick={() => removeTimeSlot(date, index)}>
          <DeleteOutlinedIcon className="icons" />
        </IconButton>
      )} */}
      {/* {index > 0 && ( */}
      <IconButton onClick={() => removeTimeSlot(date, index)}>
        <DeleteOutlinedIcon className="icons" />
      </IconButton>
      {/* )} */}
    </Box>
  );
};

const DateSelector = ({
  date,
  availability,
  handleTimeChange,
  addTimeSlot,
  removeTimeSlot,
  getAvailableTimes,
  removeDate,
}) => {
  const onlyOneSlot = availability[date].slots.length === 1;
  return (
    <Grid item>
      <Box>
        {availability[date].slots.map((slot, index) => (
          <TimeSlotSelector
            key={index}
            slot={slot}
            index={index}
            date={date}
            getAvailableTimes={getAvailableTimes}
            handleTimeChange={handleTimeChange}
            addTimeSlot={addTimeSlot}
            removeTimeSlot={removeTimeSlot}
            day={date}
            onlyOneSlot={onlyOneSlot}
          />
        ))}
      </Box>
    </Grid>
  );
};

const OverWrite = ({ open, onClose }) => {
  const [availability, setAvailability] = useState({});
  const [validDates, setValidDates] = useState([]);
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchReurringAvailability = async () => {
    setLoading(true);
    try {
      const email = sessionStorage.getItem("mail");
      const response = await getAllAvailability(email);
      setApiResponse(response);

      const validDatesFromApi = response.recurringAvailability.flatMap(
        (rec) => rec.dates
      );
      setValidDates(validDatesFromApi);
    } catch (err) {
      let msg;
      if (err?.response?.data?.exception) {
        msg = err?.response?.data?.exception;
      } else {
        msg = "Something Went Wrong!";
      }
      toast.error(msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) fetchReurringAvailability();
  }, [open]);

  useEffect(() => {
    // Remove the rbc-off-range-bg class from all elements
    const removeOffRangeClasses = () => {
      const elements = document.querySelectorAll(".rbc-off-range-bg");
      elements.forEach((element) => {
        element.classList.remove("rbc-off-range-bg");
      });
    };
    removeOffRangeClasses();
  }, [availability, validDates]);

  const handleDateSelect = (slotInfo) => {
    const dateString = moment(slotInfo.start).format("YYYY-MM-DD");
    if (!validDates.includes(dateString)) {
      toast.error("Selected date is not available for overriding.");
      return;
    }

    const allButtons = document.querySelectorAll(".rbc-button-link");
    allButtons.forEach((btn) => {
      if (btn.innerText === dateString.split("-")[2]) {
        btn.classList.toggle("selected");
      }
    });

    setAvailability((prevAvailability) => {
      const newAvailability = { ...prevAvailability };

      if (newAvailability[dateString]) {
        delete newAvailability[dateString];
      } else {
        newAvailability[dateString] = {
          slots: apiResponse.recurringAvailability
            .filter((rec) => rec.dates.includes(dateString))
            .flatMap((rec) =>
              rec.timeSlots.map((slot) => ({
                startTime: slot.fromTime,
                endTime: slot.toTime,
                timeZone: slot.timeZone,
              }))
            ),
        };
      }

      return newAvailability;
    });
  };

  const handleTimeChange = (date, index, type, event) => {
    const newTime = event.target.value;
    const slots = [...availability[date].slots];

    if (type === "startTime" && newTime === "") {
      // If start time is set to None, set end time to None as well
      slots[index].endTime = "";
    }

    slots[index][type] = newTime;

    if (type === "endTime") {
      const startTime = slots[index].startTime;
      if (isOverlapping(date, startTime, newTime, index)) {
        toast.error("Time range overlaps with existing slot!");
        slots[index][type] = ""; // Reset the time
        setAvailability({
          ...availability,
          [date]: {
            ...availability[date],
            slots,
          },
        });
        return;
      }
    } else if (type === "startTime") {
      const endTime = slots[index].endTime;
      if (isOverlapping(date, newTime, endTime, index)) {
        toast.error("Time range overlaps with existing slot!");
        slots[index][type] = ""; // Reset the time
        setAvailability({
          ...availability,
          [date]: {
            ...availability[date],
            slots,
          },
        });
        return;
      }
    }

    setAvailability({
      ...availability,
      [date]: {
        ...availability[date],
        slots,
      },
    });
  };

  const addTimeSlot = (date) => {
    setAvailability({
      ...availability,
      [date]: {
        ...availability[date],
        slots: [
          ...availability[date].slots,
          { startTime: "", endTime: "", timeZone: "Asia/Kolkata" },
        ],
      },
    });
  };

  const removeTimeSlot = (date, index) => {
    const slots = [...availability[date].slots];
    if (slots.length === 1) {
      slots[index] = { startTime: "", endTime: "", timeZone: "Asia/Kolkata" };
    } else {
      slots.splice(index, 1);
    }
    setAvailability({
      ...availability,
      [date]: {
        ...availability[date],
        slots,
      },
    });
  };

  const removeDate = (date) => {
    setAvailability((prevAvailability) => {
      const newAvailability = { ...prevAvailability };
      delete newAvailability[date];
      return newAvailability;
    });

    const allButtons = document.querySelectorAll(".rbc-button-link");
    allButtons.forEach((btn) => {
      if (btn.innerText === date.split("-")[2]) {
        btn.classList.remove("selected");
      }
    });
  };

  const isOverlapping = (date, newStartTime, newEndTime, currentSlotIndex) => {
    if (!newStartTime || !newEndTime) return false;
    const newStartIndex = timeOptions.indexOf(newStartTime);
    const newEndIndex = timeOptions.indexOf(newEndTime);
    return availability[date].slots.some((slot, index) => {
      if (index === currentSlotIndex || !slot.startTime || !slot.endTime) {
        return false;
      }
      const startIndex = timeOptions.indexOf(slot.startTime);
      const endIndex = timeOptions.indexOf(slot.endTime);
      return (
        (newStartIndex >= startIndex && newStartIndex < endIndex) ||
        (newEndIndex > startIndex && newEndIndex <= endIndex) ||
        (startIndex >= newStartIndex && startIndex < newEndIndex) ||
        (endIndex > newStartIndex && endIndex <= newEndIndex)
      );
    });
  };

  const getAvailableTimes = (date, currentSlotIndex, type) => {
    const selectedTimes = availability[date].slots
      .flatMap((slot, index) =>
        index === currentSlotIndex ? [] : [[slot.startTime, slot.endTime]]
      )
      .filter(([startTime, endTime]) => startTime && endTime);

    const availableTimes = timeOptions.filter((time) => {
      return !selectedTimes.some(([startTime, endTime]) => {
        const timeIndex = timeOptions.indexOf(time);
        const startIndex = timeOptions.indexOf(startTime);
        const endIndex = timeOptions.indexOf(endTime);
        if (type === "startTime") {
          return timeIndex >= startIndex && timeIndex < endIndex;
        } else if (type === "endTime") {
          return (
            (timeIndex > startIndex ||
              (startIndex === timeOptions.length - 1 && timeIndex === 0)) &&
            timeIndex <= endIndex
          );
        }
        return false;
      });
    });

    if (type === "endTime") {
      const startTime = availability[date].slots[currentSlotIndex].startTime;
      if (startTime) {
        const startIndex = timeOptions.indexOf(startTime);
        return availableTimes.filter(
          (time) =>
            timeOptions.indexOf(time) > startIndex ||
            (startIndex === timeOptions.length - 1 &&
              timeOptions.indexOf(time) === 0) ||
            time === "12:00 AM"
        );
      }
    }

    return availableTimes;
  };

  const handleSave = async () => {
    if (!validateData(availability)) {
      return;
    }

    // Create the payload
    const payload = createPayload(availability);
    setLoading(true);
    try {
      await saveOverrideAvailability(payload); // replace with your actual API call
      toast.success("Availability saved successfully.");
      handleDialogClose();
    } catch (err) {
      let msg;
      if (err?.response?.data?.exception) {
        msg = err?.response?.data?.exception;
      } else {
        msg = "Failed to save availability!";
      }
      toast.error(msg);
    } finally {
      setLoading(false);
    }
  };

  const validateData = (availability) => {
    const hasIncompleteSlots = Object.values(availability).some((date) =>
      date.slots.some((slot) => slot.startTime && !slot.endTime)
    );

    if (hasIncompleteSlots) {
      toast.error("All slots must have an end time.");
      return false;
    }

    const hasInvalidTimeRanges = Object.values(availability).some((date) =>
      date.slots.some((slot) => {
        const fromTimeIndex = timeOptions.indexOf(slot.startTime);
        const toTimeIndex = timeOptions.indexOf(slot.endTime);
        return (
          fromTimeIndex !== -1 &&
          toTimeIndex !== -1 &&
          toTimeIndex <= fromTimeIndex
        );
      })
    );

    if (hasInvalidTimeRanges) {
      toast.error("One or more slots have invalid time ranges.");
      return false;
    }

    return true;
  };

  const createPayload = (availability) => {
    return {
      expertId: sessionStorage.getItem("mail"),
      newDateTimeSlot: Object.entries(availability).map(([date, data]) => ({
        date,
        timeSlots: data.slots
          .filter((slot, index) => index === 0 || slot?.startTime) // Include first slot or slots with start time
          .map((slot) => ({
            fromTime: slot?.startTime,
            toTime: slot?.endTime,
            timeZone: slot?.timeZone,
          })),
      })),
    };
  };

  const dayPropGetter = (date) => {
    const dateString = moment(date).format("YYYY-MM-DD");
    const isOffRange = !moment(date).isSame(moment(), "month");
    const isValidDate = validDates.includes(dateString);

    if (availability[dateString]) {
      return {
        className: "selected",
        style: {
          backgroundColor: "blue",
          color: "#fff",
        },
      };
    } else if (isValidDate) {
      return {
        className: isOffRange ? "valid-date" : "",
        style: isOffRange ? { backgroundColor: "white", color: "inherit" } : {},
      };
    } else {
      return {
        style: {
          backgroundColor: "#f3f3f3",
          color: "#d3d3d3",
        },
      };
    }
  };

  const handleDialogClose = () => {
    setAvailability({});
    onClose();
  };

  const countSelectedDates = () => {
    return Object.values(availability).filter((day) => day).length;
  };

  return (
    <>
      <LoadingOverlay loading={loading} zIndex={1500} />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="md"
        fullWidth
        className="over-write-container"
      >
        <DialogTitle className="subtitle1">
          Select dates to override
        </DialogTitle>
        <DialogTitle className="subtitle">
          Click on dates to select or deselect :
        </DialogTitle>
        <DialogContent>
          <Calendar
            localizer={localizer}
            dayPropGetter={dayPropGetter}
            events={[]}
            defaultView="month"
            views={{ month: true }}
            style={{ height: 400 }}
            onSelectSlot={handleDateSelect}
            min={new Date()}
            max={new Date(new Date().setDate(new Date().getDate() + 90))}
            selectable="ignoreEvents"
            disabled={(event) =>
              !validDates.includes(moment(event).format("YYYY-MM-DD"))
            }
          />
          <Box mt={3}>
            {countSelectedDates() > 0 && (
              <Grid container className="headers-containers">
                <Grid item>
                  <Typography className={["table-headers", "first-item"]}>
                    {/* Day */}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="table-headers">
                    {/* Start Time */}
                    Select time
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="table-headers">
                    {/* End Time */}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="table-headers">Time Zone</Typography>
                </Grid>
              </Grid>
            )}
            {Object.keys(availability).map((date) => (
              <DateSelector
                key={date}
                date={date}
                availability={availability}
                handleTimeChange={handleTimeChange}
                addTimeSlot={addTimeSlot}
                removeTimeSlot={removeTimeSlot}
                getAvailableTimes={getAvailableTimes}
                removeDate={removeDate}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions className="action-buttons">
          <Button
            onClick={handleDialogClose}
            color="primary"
            className="button1"
          >
            Cancel
          </Button>
          {countSelectedDates() > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              className="button2"
            >
              Apply for {countSelectedDates()} date
              {countSelectedDates() > 1 && "s"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OverWrite;
