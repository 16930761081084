export const expertQuestions = [
    {
        title: "Problem Solving",
        description: [
            {
                desc: "1. Inquire about the automated /coding tests results and ask questions from the test",
            },
            {
                desc: " Ask 5 fundamental questions that will be randomly pulled from the technical question banks based on the level of the candidates",
            },
            {
                desc: "Probe more with following questions:",
                subdesc: [
                    " Explain in detail your last project worked on and the specific role played? ",
                    " Technology stack worked on the project",
                ],
            },
        ],
    },
    {
        title: "Technical Abilities(language and Efficiency of code) ",
    },
    {
        title: "Solution Approach ",
    },
];