import "./RecurringAvailability.scss";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useState, useEffect } from "react";
import {
  getRecurringAvailability,
  saveAllRecurringAvailability,
} from "../../../Apis/Availability";
import LoadingOverlay from "../../Common/LoadingOverlay";
import OverWrite from "../OverWrite/OverWrite";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const generateTimeOptions = () => {
  const options = [];
  const start = new Date();
  start.setHours(0, 0, 0, 0);
  for (let i = 0; i < 48; i++) {
    const timeString = start.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    options.push(timeString);
    start.setMinutes(start.getMinutes() + 30);
  }
  return options;
};

const timeOptions = generateTimeOptions();

const TimeSlotSelector = ({
  slot,
  index,
  day,
  getAvailableTimes,
  handleTimeChange,
  addTimeSlot,
  removeTimeSlot,
}) => {
  return (
    <Box className="each-slot">
      <Typography className="day-name">{index === 0 ? day : ""}</Typography>
      <FormControl className="start-time">
        <Select
          value={slot.startTime}
          onChange={(event) => handleTimeChange(day, index, "startTime", event)}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>Start Time</em>;
            }
            return selected;
          }}
        >
          <MenuItem disabled value="">
            <em>Start Time</em>
          </MenuItem>
          {getAvailableTimes(day, index, "startTime").map((time) => (
            <MenuItem key={time} value={time}>
              {time}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="to">to</div>
      <FormControl className="end-time">
        <Select
          value={slot.endTime}
          onChange={(event) => handleTimeChange(day, index, "endTime", event)}
          disabled={!slot.startTime}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>End Time</em>;
            }
            return selected;
          }}
        >
          <MenuItem disabled value="">
            <em>End Time</em>
          </MenuItem>
          {getAvailableTimes(day, index, "endTime").map((time) => (
            <MenuItem key={time} value={time}>
              {time}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className="time-zone">
        <Select
          value={slot.timeZone || "Asia/Kolkata"}
          onChange={(event) => handleTimeChange(day, index, "timeZone", event)}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>Time Zone</em>;
            }
            return selected;
          }}
        >
          <MenuItem disabled value="">
            <em>Time Zone</em>
          </MenuItem>
          <MenuItem value="Asia/Kolkata">Asia/Kolkata</MenuItem>
          <MenuItem value="America/New_York">America/New_York</MenuItem>
          <MenuItem value="Europe/London">Europe/London</MenuItem>
          {/* Add more time zones as needed */}
        </Select>
      </FormControl>
      <IconButton
        onClick={() => addTimeSlot(day)}
        sx={{ marginRight: 1, marginLeft: 1 }}
      >
        <AddIcon className="icons" />
      </IconButton>
      {index > 0 && (
        <IconButton onClick={() => removeTimeSlot(day, index)}>
          <DeleteOutlinedIcon className="icons" />
        </IconButton>
      )}
    </Box>
  );
};

const DaySelector = ({
  day,
  availability,
  handleTimeChange,
  addTimeSlot,
  removeTimeSlot,
  getAvailableTimes,
}) => {
  return (
    <Grid item>
      <Box>
        {availability[day].slots.map((slot, index) => (
          <TimeSlotSelector
            key={index}
            slot={slot}
            index={index}
            day={day}
            getAvailableTimes={getAvailableTimes}
            handleTimeChange={handleTimeChange}
            addTimeSlot={addTimeSlot}
            removeTimeSlot={removeTimeSlot}
          />
        ))}
      </Box>
    </Grid>
  );
};

export const RecurringAvailability = () => {
  const [availability, setAvailability] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day] = {
        selected: false,
        slots: [],
      };
      return acc;
    }, {})
  );
  const [recurringData, setRecurringData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openOverRide, setOpenOverRide] = useState(false);

  const prefillAvailability = async (data) => {
    // console.log("line first=>", data);
    const updatedAvailability = { ...availability };

    data.forEach((dayData) => {
      const day = dayData.day.toUpperCase(); // Convert "Wednesday" to "WEDNESDAY"
      //   console.log("Processed day:", day);

      const abbreviatedDay = daysOfWeek.find((d) =>
        d.toUpperCase().startsWith(day.slice(0, 3))
      );
      //   console.log("Abbreviated day:", abbreviatedDay);

      if (abbreviatedDay) {
        if (!updatedAvailability[abbreviatedDay].selected) {
          updatedAvailability[abbreviatedDay].selected = true;
        }
        // Add only the prefilled slots
        dayData.timeSlots.forEach((slot) => {
          updatedAvailability[abbreviatedDay].slots.push({
            startTime: slot.fromTime,
            endTime: slot.toTime,
            timeZone: slot.timeZone,
          });
        });
      } else {
        console.log("Day not included in daysOfWeek:", day);
      }
    });

    // console.log("line second=>", updatedAvailability);

    setAvailability({ ...updatedAvailability });
  };

  const handleCheckboxChange = (day, event) => {
    const isSelected = event.target.checked;

    setAvailability((prevAvailability) => ({
      ...prevAvailability,
      [day]: {
        ...prevAvailability[day],
        selected: isSelected,
        slots: isSelected
          ? prevAvailability[day].slots.length > 0
            ? prevAvailability[day].slots
            : [{ startTime: "", endTime: "", timeZone: "Asia/Kolkata" }]
          : [],
      },
    }));
  };

  const handleTimeChange = (day, index, type, event) => {
    const newTime = event.target.value;
    const slots = [...availability[day].slots];
    slots[index][type] = newTime;

    if (type === "endTime") {
      const startTime = slots[index].startTime;
      if (isOverlapping(day, startTime, newTime, index)) {
        toast.error("Time range overlaps with existing slot!");
        slots[index][type] = ""; // Reset the time
        setAvailability({
          ...availability,
          [day]: {
            ...availability[day],
            slots,
          },
        });
        return;
      }
    } else if (type === "startTime") {
      const endTime = slots[index].endTime;
      if (isOverlapping(day, newTime, endTime, index)) {
        toast.error("Time range overlaps with existing slot!");
        slots[index][type] = ""; // Reset the time
        setAvailability({
          ...availability,
          [day]: {
            ...availability[day],
            slots,
          },
        });
        return;
      }
    }

    setAvailability({
      ...availability,
      [day]: {
        ...availability[day],
        slots,
      },
    });
  };

  const addTimeSlot = (day) => {
    setAvailability({
      ...availability,
      [day]: {
        ...availability[day],
        slots: [
          ...availability[day].slots,
          { startTime: "", endTime: "", timeZone: "Asia/Kolkata" },
        ],
      },
    });
  };

  const removeTimeSlot = (day, index) => {
    const slots = [...availability[day].slots];
    slots.splice(index, 1);
    setAvailability({
      ...availability,
      [day]: {
        ...availability[day],
        slots,
      },
    });
  };

  const isTimeInRange = (time, start, end) => {
    const timeIndex = timeOptions.indexOf(time);
    const startIndex = timeOptions.indexOf(start);
    const endIndex = timeOptions.indexOf(end);
    return timeIndex > startIndex && timeIndex < endIndex;
  };

  const isOverlapping = (day, newStartTime, newEndTime, currentSlotIndex) => {
    if (!newStartTime || !newEndTime) return false;
    const newStartIndex = timeOptions.indexOf(newStartTime);
    const newEndIndex = timeOptions.indexOf(newEndTime);
    return availability[day].slots.some((slot, index) => {
      if (index === currentSlotIndex || !slot.startTime || !slot.endTime) {
        return false;
      }
      const startIndex = timeOptions.indexOf(slot.startTime);
      const endIndex = timeOptions.indexOf(slot.endTime);
      return (
        (newStartIndex >= startIndex && newStartIndex < endIndex) ||
        (newEndIndex > startIndex && newEndIndex <= endIndex) ||
        (startIndex >= newStartIndex && startIndex < newEndIndex) ||
        (endIndex > newStartIndex && endIndex <= newEndIndex)
      );
    });
  };

  const getAvailableTimes = (day, currentSlotIndex, type) => {
    const selectedTimes = availability[day].slots
      .flatMap((slot, index) =>
        index === currentSlotIndex ? [] : [[slot.startTime, slot.endTime]]
      )
      .filter(([startTime, endTime]) => startTime && endTime);

    const availableTimes = timeOptions.filter((time) => {
      return !selectedTimes.some(([startTime, endTime]) => {
        const timeIndex = timeOptions.indexOf(time);
        const startIndex = timeOptions.indexOf(startTime);
        const endIndex = timeOptions.indexOf(endTime);
        if (type === "startTime") {
          return timeIndex >= startIndex && timeIndex < endIndex;
        } else if (type === "endTime") {
          return (
            (timeIndex > startIndex ||
              (startIndex === timeOptions.length - 1 && timeIndex === 0)) &&
            timeIndex <= endIndex
          );
        }
        return false;
      });
    });

    if (type === "endTime") {
      const startTime = availability[day].slots[currentSlotIndex].startTime;
      if (startTime) {
        const startIndex = timeOptions.indexOf(startTime);
        return availableTimes.filter(
          (time) =>
            timeOptions.indexOf(time) > startIndex ||
            (startIndex === timeOptions.length - 1 &&
              timeOptions.indexOf(time) === 0) ||
            time === "12:00 AM"
        );
      }
    }

    return availableTimes;
  };

  const performChecks = (availability) => {
    // Check for incomplete slots
    const hasIncompleteSlots = Object.values(availability).some((day) =>
      day.slots.some((slot) => slot.startTime && !slot.endTime)
    );

    if (hasIncompleteSlots) {
      toast.error("All slots must have an end time.");
      return false;
    }

    // Check for invalid time ranges
    const hasInvalidTimeRanges = Object.values(availability).some((day) =>
      day.slots.some((slot) => {
        const fromTimeIndex = timeOptions.indexOf(slot.startTime);
        const toTimeIndex = timeOptions.indexOf(slot.endTime);
        return (
          fromTimeIndex !== -1 &&
          toTimeIndex !== -1 &&
          toTimeIndex <= fromTimeIndex
        );
      })
    );

    if (hasInvalidTimeRanges) {
      toast.error("One or more slots have invalid time ranges.");
      return false;
    }

    return true;
  };

  const createPayload = (availability) => {
    console.log("Availability received =>", availability);
    const expertId = sessionStorage.getItem("mail");
    const startDate = new Date().toISOString().split("T")[0]; // Get the current date in YYYY-MM-DD format

    const dayMapping = {
      Sun: "Sunday",
      Mon: "Monday",
      Tue: "Tuesday",
      Wed: "Wednesday",
      Thu: "Thursday",
      Fri: "Friday",
      Sat: "Saturday",
    };

    // const recurringAvailability = Object.entries(availability)
    //   .filter(([day, data]) => data.selected && data.slots.length > 0) // Filter out days with no slots
    //   .map(([day, data]) => ({
    //     day: dayMapping[day], // Convert abbreviated day to full day name
    //     timeSlots: data.slots.map((slot) => ({
    //       fromTime: slot.startTime,
    //       toTime: slot.endTime,
    //       timeZone: slot.timeZone,
    //     })),
    //   }));

    const recurringAvailability = Object.entries(availability)
      .filter(
        ([day, data]) =>
          data.selected &&
          data.slots.some((slot) => slot.startTime && slot.endTime)
      )
      .map(([day, data]) => ({
        day: dayMapping[day],
        timeSlots: data.slots
          .filter((slot) => slot.startTime && slot.endTime)
          .map((slot) => ({
            fromTime: slot.startTime,
            toTime: slot.endTime,
            timeZone: slot.timeZone,
          })),
      }));

    return {
      expertId,
      months: 3,
      startDate,
      recurringAvailability,
    };
  };

  const handleSave = async () => {
    if (!performChecks(availability)) {
      return;
    }

    const payload = createPayload(availability);
    setLoading(true);
    try {
      await saveAllRecurringAvailability(payload); // replace with your actual API call
      toast.success("Availability saved successfully.");
      // fetchReurringAvailability();
    } catch (err) {
      let msg;
      if (err?.response?.data?.exception) {
        msg = err?.response?.data?.exception;
      } else {
        msg = "Failed to save availability!";
      }
      toast.error(msg);
    } finally {
      setLoading(false);
    }
  };

  const fetchReurringAvailability = async () => {
    setLoading(true);
    try {
      const email = sessionStorage.getItem("mail");
      const data = await getRecurringAvailability(email);
      setRecurringData([...data]);
      await prefillAvailability(data);
    } catch (err) {
      let msg;
      if (err?.response?.data?.exception) {
        msg = err?.response?.data?.exception;
      } else {
        msg = "Something Went Wrong!";
      }
      toast.error(msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReurringAvailability();
  }, []);

  return (
    <>
      <LoadingOverlay loading={loading} />
      <Box className="recurring-container">
        <ToastContainer />
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <FormGroup className="days-container">
              {daysOfWeek.map((day) => (
                <FormControlLabel
                  key={day}
                  control={
                    <Checkbox
                      checked={availability[day].selected}
                      onChange={(event) => handleCheckboxChange(day, event)}
                    />
                  }
                  label={day}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className="override-button"
              onClick={() => setOpenOverRide(true)}
            >
              Override Availability
            </Button>
          </Grid>
        </Grid>
        <Box>
          <Grid container className="headers-containers">
            <Grid item>
              <Typography className={["table-headers", "first-item"]}>
                {/* Day */}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="table-headers">
                {/* Start Time */}
                Select time
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="table-headers">
                {/* End Time */}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className="table-headers">Time Zone</Typography>
            </Grid>
          </Grid>
          <FormGroup>
            <Grid container direction="column">
              {daysOfWeek.map(
                (day) =>
                  availability[day].selected && (
                    <DaySelector
                      key={day}
                      day={day}
                      availability={availability}
                      handleTimeChange={handleTimeChange}
                      addTimeSlot={addTimeSlot}
                      removeTimeSlot={removeTimeSlot}
                      getAvailableTimes={getAvailableTimes}
                    />
                  )
              )}
            </Grid>
          </FormGroup>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          className="custom-button"
          // sx={{ marginTop: 2 }}
        >
          Save
        </Button>
      </Box>

      <OverWrite open={openOverRide} onClose={() => setOpenOverRide(false)} />
    </>
  );
};
