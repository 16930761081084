import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Avatar, IconButton } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useState } from "react";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import zeeroLogo from "../../Assets/zeero.svg";
import { expertLogOut } from "../../Apis/login";

export const Header = () => {
  const [auth, setAuth] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [icon, setIcon] = useState();

  const dropDownContent = [
    {
      title: "Logout",
      function: () => onClickLogout(),
    },
  ];

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function onClickLogout() {
    try {
      expertLogOut();
      navigate("/");
    } catch (err) {
      console.error("error in logout", err);
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem("token") !== undefined) {
      setAuth(true);
    }
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className="candidate-header">
        <AppBar position="static" className="header-wrapper">
          <Toolbar>
            <div style={{ marginLeft: "3vw", width: "44px", height: "44px" }}>
              <div
                href=""
                className="logo"
                onClick={() => navigate("/dashboard")}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={zeeroLogo}
                  className="wissdalogo"
                  alt="logo"
                />
              </div>
            </div>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            ></Typography>

            {auth && (
              <>
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  title="account"
                  onClick={handleMenu}
                  color="inherit"
                  className="account-icon"
                  sx={{
                    ml: "30px",
                    "@media (max-width: 600px)": {
                      ml: "10px", // Adjust the left margin for smaller screens
                    },
                    "@media (max-width: 400px)": {
                      ml: "5px", // Further adjust the left margin for even smaller screens
                    },
                  }}
                >
                  {icon != null ? (
                    <Avatar sx={{ bgcolor: "#3f51b5" }}>{icon} </Avatar>
                  ) : (
                    <AccountCircle />
                  )}
                </IconButton>
                <Menu
                  sx={{ mt: "25px" }}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {dropDownContent.map((item) => (
                    <MenuItem
                      key={item.title}
                      onClick={item.function}
                    >{`${item.title}`}</MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};
