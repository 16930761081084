import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCandidateId } from "../../Apis/candidateDetails";
import LoadingOverlay from "../../Component/Common/LoadingOverlay";
import { Header } from "../../Component/Header/Header";
import { DetailsCard } from "../../Component/AssessmentResult/DetailsCard/DetailsCard";
import { expertQuestions as items } from "../../mockdata/expertQuestions";
import { ComentsAndDescription } from "../../Component/AssessmentResult/ComentsAndDescription/ComentsAndDescription";
import { toast } from "react-toastify";
import { RatingsCard } from "../../Component/AssessmentResult/RatingsCard/RatingsCard";
import "./AssessmentResult.scss";
import { candidateSkillsForExpertRound } from "../../Apis/candidateSkillsForExpertRound";
import { Button, IconButton, Modal, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import congrats from "../../Assets/results/congrats.svg";
import { SubmitRound5Result } from "../../Apis/submitExpertResult";
import { submitRound } from "../../Apis/roundSubmission";

const MAX_RATING = 5;

export const AssessmentResult = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItem5, setSelectedItem5] = useState(null);
  const [ratings, setRatings] = useState(Array(items.length).fill(0));
  const [inputForR4, setinputForR4] = useState(Array(items.length).fill(""));
  const [candidateProfile, setCandidateProfile] = useState({});
  const [disableJoin, setDisableJoin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRatings, setTotalRatings] = useState(0);
  const [comments, setComments] = useState("");
  const [r5Titles, setR5Titles] = useState([]);
  const [ratingsR5, setRatingsR5] = useState([]);
  const [inputForR5, setinputForR5] = useState([]);
  const [totalRatingsR5, setTotalRatingsR5] = useState(0);
  const [viewFinal, setViewFinal] = useState(false);
  const [viewLastSuccessCard, setViewLastSuccessCard] = useState(false);

  const [meetingDetails] = useState({
    start: queryParams.get("start"),
    end: queryParams.get("end"),
    timezone: queryParams.get("timezone"),
    title: queryParams.get("title"),
    joinUrl: queryParams.get("join_url"),
    meet_id: queryParams.get("meet_id"),
  });

  const handleCandidateId = async () => {
    setLoading(true);
    try {
      const res = await getCandidateId(meetingDetails.meet_id);
      setCandidateProfile({ ...res });

      if (res?.candidateRoundStatus?.round5 !== null) {
        // setDisableJoin(true);
        // making it false to enable the join button all the time
        setDisableJoin(false);
      }

      if (res?.developerResponse?.id) {
        handleR5Titles(res?.developerResponse?.id);
      }
    } catch (err) {
      console.log("error  in handleCandidateId ==>", err);
    } finally {
      setLoading(false);
    }
  };

  const handleR5Titles = async (candidateId) => {
    try {
      let data = {
        id: candidateId,
      };
      const res = await candidateSkillsForExpertRound(data);
      // console.log("handleR5Titles ==>", res);
      setR5Titles([...res]);
      setRatingsR5(Array(res.length).fill(0));
      setinputForR5(Array(res.length).fill(""));
    } catch (err) {
      console.log("handleR5Titles ==>", err);
    }
  };

  const handleResultSubmitClick = () => {
    try {
      if (allRatingsPresent()) {
        setViewFinal(true);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const allRatingsPresent = () => {
    for (let i = 0; i < ratings.length; i++) {
      if (ratings[i] === 0) {
        toast.error("Please Select all the ratings");
        return false;
      }
    }

    for (let i = 0; i < ratingsR5.length; i++) {
      if (ratingsR5[i] === 0) {
        toast.error("Please Select all the ratings");
        return false;
      }
    }

    if (!comments) {
      toast.error("Please submit overall comment");
      return false;
    }

    return true;
  };

  const handleItemClick = (index) => {
    setSelectedItem(index === selectedItem ? null : index);
  };
  const handleItemClick5 = (index) => {
    setSelectedItem5(index === selectedItem5 ? null : index);
  };

  const handleTotalRatings = (data) => {
    console.log("ratings r4==>", data);
    let total = 0;
    let count = 0;
    data.forEach((element) => {
      // if (element) {
      total += element;
      count++;
      // }
    });
    let resRating = total / count;
    resRating = resRating.toFixed(2);
    setTotalRatings(resRating);
  };
  const handleTotalRatingsR5 = (data) => {
    console.log("ratings r5==>", data);

    let total = 0;
    let count = 0;
    data.forEach((element) => {
      // if (element) {
      total += element;
      count++;
      // }
    });
    let resRating = total / count;
    resRating = resRating.toFixed(2);
    setTotalRatingsR5(resRating);
  };

  const handleRatingChange = (index, rating) => {
    const newRatings = [...ratings];
    newRatings[index] = rating;
    handleTotalRatings(newRatings);
    setRatings(newRatings);
  };

  const handleR4Input = (e, index) => {
    let temp = inputForR4;
    temp[index] = e.target.value;
    setinputForR4([...temp]);
  };
  const handleR5Input = (e, index) => {
    let temp = inputForR5;
    temp[index] = e.target.value;
    setinputForR5([...temp]);
  };

  const handleRatingChange5 = (index, rating) => {
    const newRatings = [...ratingsR5];
    newRatings[index] = rating;
    handleTotalRatingsR5(newRatings);
    setRatingsR5(newRatings);
  };

  const handleFinalClose = () => {
    setViewFinal(false);
  };

  const handleFinalSubmit = async () => {
    setLoading(true);
    try {
      const id = candidateProfile?.developerResponse?.id;

      // First API call
      const res = await SubmitRound5Result(
        items,
        ratings,
        comments,
        inputForR4,
        totalRatings,
        r5Titles,
        ratingsR5,
        inputForR5,
        id
      );
      console.log("First API call result ===>", res);

      // Second API call
      const res2 = await submitRound(id);
      console.log("Second API call result ===>", res2);

      // Third API call
      const res3 = await SubmitRound5Result(
        items,
        ratings,
        comments,
        inputForR4,
        totalRatings,
        r5Titles,
        ratingsR5,
        inputForR5,
        id
      );
      console.log("Third API call result ===>", res3);

      setViewLastSuccessCard(true);
    } catch (err) {
      console.log("handleSubmit error ===>", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleCandidateId();
  }, [meetingDetails]);

  return (
    <>
      {loading && <LoadingOverlay loading={loading} zIndex={1500} />}
      <Header />
      <div className="assessment-container">
        <DetailsCard
          meetingDetails={meetingDetails}
          candidateProfile={candidateProfile}
          disableJoin={disableJoin}
        />
        <div className="results-container">
          <RatingsCard
            items={items}
            handleItemClick={handleItemClick}
            ratings={ratings}
            handleRatingChange={handleRatingChange}
            handleR4Input={handleR4Input}
            selectedItem={selectedItem}
            inputForR4={inputForR4}
            totalRatings={totalRatings}
            // below are r5 details
            totalRatingsR5={totalRatingsR5}
            r5Titles={r5Titles}
            ratingsR5={ratingsR5}
            inputForR5={inputForR5}
            handleR5Input={handleR5Input}
            handleRatingChange5={handleRatingChange5}
            selectedItem5={selectedItem5}
            handleItemClick5={handleItemClick5}
            MAX_RATING={MAX_RATING}
          />
          <ComentsAndDescription
            comments={comments}
            setComments={setComments}
            handleResultSubmitClick={handleResultSubmitClick}
            disableJoin={disableJoin}
          />
        </div>
      </div>

      <FinalSubmit
        open={viewFinal}
        handleClose={handleFinalClose}
        handleFinalSubmit={handleFinalSubmit}
      />

      <LastSuccessCard open={viewLastSuccessCard} />
    </>
  );
};

const FinalSubmit = ({ open, handleClose, handleFinalSubmit }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Paper className="modal">
        <IconButton className="close-button" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" className="title">
          Are you sure you want to submit the results?
        </Typography>
        <Typography className="title2">
          Once submitted you will not be able to modify any ratings or comments.
        </Typography>
        <div>
          <Button className="cancel" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button className="nextButton" onClick={() => handleFinalSubmit()}>
            Submit
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

const LastSuccessCard = ({ open }) => {
  const navigate = useNavigate();
  return (
    <Modal open={open}>
      <Paper className="modal-last">
        <div>
          <img src={congrats} alt="" />
        </div>
        <Typography variant="h6" className="title">
          Congratulations!
        </Typography>
        <Typography className="title2">
          The results has been submitted successfully!
        </Typography>
        <div>
          <Button className="nextButton" onClick={() => navigate("/dashboard")}>
            Back to Dashboard
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};
