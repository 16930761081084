import { DOWNLOAD_QUESTIONS_PDF, DOWNLOAD_SUBMITTED_ASSIGNMENT } from "./URL";
import axiosInstance from "./axiosInstance";

export const downloadPDFQuestion = async (data) => {

  const URL = DOWNLOAD_QUESTIONS_PDF

  const config = {
    url: URL,
    method: "POST",
    responseType: "blob", // Important for handling the binary data of the PDF
    data: data,
    headers: {
      // headers here (e.g., Authorization)
    },
  };

  try {
    const response = await axiosInstance(config);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "downloaded-file.pdf"); // or any other name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading the PDF file: ", error);
  }
};



export const downloadZipProject = async (data) => {

  const URL = DOWNLOAD_SUBMITTED_ASSIGNMENT;

  const config = {
    url: URL, // the URL to request the ZIP
    method: "POST",
    responseType: "blob", // Important for handling the binary data of the ZIP
    data: data,
    headers: {
      //  headers here (e.g., Authorization)
    },
  };

  try {
    const response = await axiosInstance(config);
    // console.log("Response received:", response); // Log the response for debugging
    if (response.data.size === 0) {
      console.error("Received an empty file");
      return;
    }
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "downloaded-file.zip"); // or any other name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error during download:", error);
  }
};
