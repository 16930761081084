
import { MONTH_EVENT } from "./URL";
import axiosInstance from "./axiosInstance";

export const currentMonthEvents = async () => {
  let Config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  const endPoint = MONTH_EVENT

  const data = currentMonthData();

  return await axiosInstance.post(endPoint, data, Config).then((res) => res.data);
};

const currentMonthData = () => {
  // Get today's date
  var today = new Date();

  // Extract the month number from the date
  var month = String(today.getMonth() + 1).padStart(2, "0");

  // Format the date as "YYYY-MM-DD"
  console.log(
    "checkDateEmpty",
    today.getDate() < 10 ? `0${today.getDate()}` : today.getDate(),
    "c",
    today.getDate() < 10
  );

  const todayDate =
    today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();

  var formattedDate = today.getFullYear() + "-" + month + "-" + todayDate;

  console.log("formattedDate==>", formattedDate);

  const data = {
    month: month,
    date: formattedDate,
    expertEmail: sessionStorage.getItem("mail"),
  };
  return data;
};


